import React, { useContext, useEffect, useState } from "react";
import { cacheBuster } from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  const [tabs] = useState([
    "tv9gujarati",
    "abpasmita",
    "sandeshnews",
    "news18gujarati",
    "zee24kalak",
    "indianewsgujarat",
    "cnbcbajar",
  ]);

  const [selectedGujaratiTab, setSelectedGujaratiTab] = useState<string>(
    () => localStorage.getItem("selectedGujaratiTab") || "tv9gujarati"
  );

  // Handle tab selection
  const handleTabClick = (channelKey: string) => {
    setSelectedGujaratiTab(channelKey);
    localStorage.setItem("selectedGujaratiTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedGujaratiTab");
    if (savedTab) {
      setSelectedGujaratiTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["cnbctv18", "cnnnews18"];
  const hiddenTabs: any = [""];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedGujaratiTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedGujaratiTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const tv9gujarati = `/assets/channel-logos/tv9-gujarati.jpeg?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const abpasmita = `/assets/channel-logos/abp-asmita.png?v=${cacheBuster}`;
  const sandeshnews = `/assets/channel-logos/sandesh-news.jpeg?v=${cacheBuster}`;
  const news18gujarati = `/assets/channel-logos/news18-gujarati.png?v=${cacheBuster}`;
  const zee24kalak = `/assets/channel-logos/zee-24-kalak.png?v=${cacheBuster}`;
  const indianewsgujarat = `/assets/channel-logos/india-news-gujarat.jpeg?v=${cacheBuster}`;
  const cnbcbajar = `/assets/channel-logos/cnbc-bajar.jpeg?v=${cacheBuster}`;

  const imagesToPreload = [
    tv9gujarati,
    abpasmita,
    sandeshnews,
    news18gujarati,
    zee24kalak,
    indianewsgujarat,
    cnbcbajar,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Gujarati News Live | TV9 Gujarati, ABP Asmita, Sandesh News, News18
            Gujarati, Zee 24 Kalak, India News Gujarat, CNBC Bajar
          </title>
          <meta
            name="description"
            content="Watch live streaming of TV9 Gujarati, ABP Asmita, Sandesh News, News18 Gujarati, Zee 24 Kalak, India News Gujarat, and CNBC Bajar on Gujarati-News.live. Stay updated with the latest Gujarati news from trusted channels."
          />
          <meta
            name="keywords"
            content="Gujarati news live, TV9 Gujarati live, ABP Asmita live, Sandesh News live, News18 Gujarati live, Zee 24 Kalak live, India News Gujarat live, CNBC Bajar live, Gujarati breaking news, Gujarat live news streaming, Gujarati news channels"
          />
        </Helmet>
        <Header />

        {selectedOption === "Gujarati" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("tv9gujarati") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "tv9gujarati" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("tv9gujarati")}
                  >
                    {/**** When you add new logo change the src={tv9gujarati} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tv9gujarati}
                        alt="TV9 Gujarati Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={tv9gujarati} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("abpasmita") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "abpasmita" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("abpasmita")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={abpasmita}
                        alt="ABP Asmita Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("sandeshnews") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "sandeshnews" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("sandeshnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={sandeshnews}
                        alt="Sandesh News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("news18gujarati") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "news18gujarati" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("news18gujarati")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18gujarati}
                        alt="News18 Gujarati Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("zee24kalak") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "zee24kalak" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("zee24kalak")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zee24kalak}
                        alt="Zee 24 Kalak Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("indianewsgujarat") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "indianewsgujarat" ? "active" : ""
                    } order-6`}
                    onClick={() => handleTabClick("indianewsgujarat")}
                  >
                    <div className="image-container">
                      <img
                        rel="preload"
                        height="80"
                        width="80"
                        src={indianewsgujarat}
                        alt="India News Gujarat Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("cnbcbajar") && (
                  <div
                    className={`tab-item ${
                      selectedGujaratiTab === "cnbcbajar" ? "active" : ""
                    } order-7`}
                    onClick={() => handleTabClick("cnbcbajar")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={cnbcbajar}
                        alt="CNBC Bajar Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video  */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedGujaratiTab === "tv9gujarati" &&
                visibleTabs.includes("tv9gujarati") && (
                  <>
                    <h1>TV9 Gujarati Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/-lzRnfB2CWA?si=-CB3NfSxifIYDGl3&autoplay=1&v=${cacheBuster}`}
                      title="TV9 Gujarati Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedGujaratiTab === "abpasmita" &&
                visibleTabs.includes("abpasmita") && (
                  <>
                    <h1>ABP Asmita Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/OF1PR4b-1Bk?si=H0qlMVtHGEeTlAlz&autoplay=1&v=${cacheBuster}`}
                      title="ABP Asmita Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "sandeshnews" &&
                visibleTabs.includes("sandeshnews") && (
                  <>
                    <h1>Sandesh News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/vAXkptIo6Cs?si=H-fY0UBA5N9ujlol&autoplay=1&v=${cacheBuster}`}
                      title="Sandesh News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "news18gujarati" &&
                visibleTabs.includes("news18gujarati") && (
                  <>
                    <h1>News18 Gujarati Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/oURmdbfbhgM?si=HacTjd99vzRw7__0&autoplay=1&v=${cacheBuster}`}
                      title="News18 Gujarati Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "zee24kalak" &&
                visibleTabs.includes("zee24kalak") && (
                  <>
                    <h1>Zee 24 Kalak Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/tm9c0c-qEXg?si=Cu-xvyAzNO6vTCev&autoplay=1&v=${cacheBuster}`}
                      title="Zee 24 Kalak Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "indianewsgujarat" &&
                visibleTabs.includes("indianewsgujarat") && (
                  <>
                    <h1>India News Gujarat Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/3iw2HKBQYlc?si=GT2lpZD1TBJh7sCB&autoplay=1&v=${cacheBuster}`}
                      title="India News Gujarat Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedGujaratiTab === "cnbcbajar" &&
                visibleTabs.includes("cnbcbajar") && (
                  <>
                    <h1>CNBC Bajar Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/LziO-1JrvQo?si=8scYz5cIj35eKhNV&autoplay=1&v=${cacheBuster}`}
                      title="CNBC Bajar Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
