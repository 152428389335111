import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Privacy Policy | Gujarati News Live | TV9 Gujarati, ABP Asmita,
            Sandesh News, News18 Gujarati, Zee 24 Kalak, CNBC Bajar
          </title>
          <meta
            name="description"
            content="Read the privacy policy for www.gujarati-news.in. Learn about cookie usage, third-party content, and data protection policies. Watch live Gujarati news from TV9 Gujarati, ABP Asmita, Sandesh News, and more."
          />
          <meta
            name="keywords"
            content="Privacy policy, Gujarati news live, TV9 Gujarati live, ABP Asmita live, Sandesh News live, News18 Gujarati live, Zee 24 Kalak live, CNBC Bajar live, data protection, cookie policy, external links, privacy practices"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            Welcome to Gujarati News Live, accessible at www.gujarati-news.in.
            We are committed to protecting your privacy and providing
            transparency on how we collect, use, and manage your information.
            This policy explains our practices regarding the information we
            collect and how you can control your data.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information We Collect</h2>
              <p>
                We do not collect personal information unless you voluntarily
                provide it when contacting us via email. However, we may collect
                non-personal data such as your browser type, IP address, and
                user interaction data to help improve your browsing experience.
              </p>
            </li>
            <li>
            <h2>Third-Party Content and Links</h2>
              <p>
                Our website provides live streaming links to Gujarati news
                channels through embedded YouTube videos, including:
              </p>
              <ul className="dash-list">
                <li>
                  TV9 Gujarati – Watch live at [TV9 Gujarati Live] (
                  <h3><a
                    href="https://www.youtube.com/watch?v=-lzRnfB2CWA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=-lzRnfB2CWA
                  </a></h3>
                  )
                </li>
                <li>
                  ABP Asmita – Watch live at [ABP Asmita Live] (
                  <h3><a
                    href="https://www.youtube.com/watch?v=OF1PR4b-1Bk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=OF1PR4b-1Bk
                  </a></h3>
                  )
                </li>
                <li>
                  Sandesh News – Watch live at [Sandesh News Live] (
                  <h3><a
                    href="https://www.youtube.com/watch?v=vAXkptIo6Cs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=vAXkptIo6Cs
                  </a></h3>
                  )
                </li>
                <li>
                  News18 Gujarati – Watch live at [News18 Gujarati Live] (
                  <h3><a
                    href="https://www.youtube.com/watch?v=oURmdbfbhgM"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=oURmdbfbhgM
                  </a></h3>
                  )
                </li>
                <li>
                  Zee 24 Kalak – Watch live at [Zee 24 Kalak Live] (
                  <h3><a
                    href="https://www.youtube.com/watch?v=tm9c0c-qEXg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=tm9c0c-qEXg
                  </a></h3>
                  )
                </li>
                <li>
                  India News Gujarat – Watch live at [India News Gujarat Live] (
                  <h3><a
                    href="https://www.youtube.com/watch?v=3iw2HKBQYlc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=3iw2HKBQYlc
                  </a></h3>
                  )
                </li>
                <li>
                  CNBC Bajar – Watch live at [CNBC Bajar Live] (
                  <h3><a
                    href="https://www.youtube.com/watch?v=LziO-1JrvQo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=LziO-1JrvQo
                  </a></h3>
                  )
                </li>
              </ul>
              <p>
                We do not own the content or videos embedded on our website. All
                rights and content belong to the respective owners.
              </p>
            </li>
            <li>
            <h2>Cookies</h2>
              <p>
                Our website may use cookies to improve user experience by
                analyzing traffic data. These cookies collect anonymous
                information and help us understand how visitors interact with
                our site.
              </p>
            </li>
            <li>
            <h2>Data Protection</h2>
              <p>
                We take the security of your data seriously and ensure that no
                sensitive personal information is stored on our platform. Your
                browsing data is protected with industry-standard security
                measures.
              </p>
            </li>
            <li>
              <h3>External Links</h3>
              <p>
                We link to third-party websites (YouTube) for live streaming of
                news channels. Once you click on these links, you will be
                subject to their privacy policies. Please review the privacy
                policies of YouTube for more information.
              </p>
            </li>
            <li>
             <h3> Contact Us</h3>
              <p>
                If you have any concerns or questions about our privacy policy,
                please contact us at contact@gujarati-news.in.
              </p>
            </li>
          </ol>
          <h3>
            We are a news aggregator and do not claim ownership over any
            third-party content. All content rights remain with the original
            owners.
          </h3>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
